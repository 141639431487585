// scss/css
import "./styles/globals.css";

// components
import AboutUs from "./components/about-us";
import BSCollabs from "./components/collabs";
import Contact from "./components/contact";
import Footer from "./components/footer";
import GlobMarkets from "./components/glob-markets";
import Hero from "./components/hero";
import NavBar from "./components/nav-bar";
import Products from "./components/products";
import TechServices from "./components/tech-services";
import MobileNav from "./components/mobile-nav";
import PWDBy from "./components/powered-by";

// react
import { useState } from "react";

import PhilippineMarket from "./components/philippine-markets";

export default function App() {
  const [mobileNav, setMobileNav] = useState(false);

  const handleNav = () => {
    setMobileNav(!mobileNav);
  };

  return (
    <>
      <MobileNav expand={mobileNav} setNav={handleNav} />
      <div>
        <NavBar setNav={handleNav} />
        <Hero />
        <BSCollabs />
        <AboutUs />
        <GlobMarkets />
        <PhilippineMarket />
        <TechServices />
        <Products />
        <Contact />
        <Footer />
        <PWDBy />
      </div>
    </>
  );
}
